const DOMAINS_ENDPOINTS: { [key: string]: string } = {
  'housekeeping.dev.bookingfactory.io': 'https://dev.thebookingfactory.com',
  'housekeeping.bookingfactory.io': 'https://app.thebookingfactory.com',
  'housekeeping.test.bookingfactory.io': 'https://app.test.thebookingfactory.com',
  'housekeeping.frontdesk.travelbookgroup.com': 'https://frontdesk.travelbookgroup.com',
  'housekeeping.ngeluwunganhotelsoftware.com': 'https://app.ngeluwunganhotelsoftware.com',
  'housekeeping.frontdesk.webconnection.asia': 'https://frontdesk.webconnection.asia',
  localhost: 'http://localhost:3000',
};

export default DOMAINS_ENDPOINTS;

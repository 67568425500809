import DOMAINS_ENDPOINTS from './domains_endpoints';

const getApiBaseUrl = () => {
  const hostname = window.location.hostname;
  if (DOMAINS_ENDPOINTS[hostname]) {
    return DOMAINS_ENDPOINTS[hostname];
  }

  const apiBaseUrl = 'https://' + hostname.replace('housekeeping', 'app');
  return apiBaseUrl;
};

export default {
  isProduction: process.env.NODE_ENV === 'production',
  apiBaseUrl: getApiBaseUrl(),
};

import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import DOMAINS_ENDPOINTS from './domains_endpoints';
import LoginPage from './pages/LoginPage/LoginPage';
import { useAuth } from './hooks/useAuth';
import IndexPage from './pages/IndexPage/IndexPage';
import GlobalLoader from './components/GlobalLoader/GlobalLoader';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import RoomDetailsPage from './pages/RoomDetailsPage/RoomDetailsPage';

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

// Suspense is only used for i18next for now. For now, we show just a blank div as a fallback,
// since the translations load very quickly anyway
const App: React.FC = () => {
  useEffect(() => {
    const link = document.getElementById('favicon') as HTMLLinkElement;
    const appleIcon = document.querySelector("link[rel='apple-touch-icon']") as HTMLLinkElement;

    const hostname = window.location.hostname;
    const url = DOMAINS_ENDPOINTS[hostname]
      ? DOMAINS_ENDPOINTS[hostname]
      : 'https://' + hostname.replace('housekeeping', 'app');
    const faviconUrl = `${url}/api/public/v1/favicon`;

    if (link) {
      link.href = faviconUrl;
    }
    if (appleIcon) {
      appleIcon.href = faviconUrl;
    }
  }, []);

  return (
    <Suspense fallback={<div />}>
      <GlobalLoader />
      <Router>
        <Switch>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <PrivateRoute exact path="/">
            <IndexPage />
          </PrivateRoute>
          <PrivateRoute exact path="/rooms/:id">
            <RoomDetailsPage />
          </PrivateRoute>
          <Route>
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </Suspense>
  );
};

export default App;

import { AuthError } from './../errors/AuthError';
import { IAuthClient } from './IAuthClient';
import { IApiClient, ICallOptions } from './IApiClient';
import env from '../env';

export default class ApiClient implements IApiClient {
  constructor(private authClient: IAuthClient) {}
  private baseUrl = env.apiBaseUrl + '/api/v1';

  public async call<T>(endpoint: string, opts?: ICallOptions): Promise<T> {
    let checkSession = opts?.checkSession;
    if (checkSession === undefined) checkSession = true;
    if (checkSession) this.authClient.verifyHasValidSession();

    let url = `${this.baseUrl}/${endpoint}`;
    if (opts?.queryString) {
      url += `?${opts.queryString}`;
    }

    const response = await fetch(url, {
      method: opts?.method || 'GET',
      body: opts?.body ? JSON.stringify(opts.body) : undefined,
      headers: this.generateHeaders(),
    })
      .then(this.handleResponse)
      .catch(this.handleError);

    return response;
  }

  private handleResponse = (response: Response) => {
    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new AuthError(response.statusText, response.status);
      }
      throw new Error(response.statusText);
    }

    return response.json();
  };

  private handleError = (error: Error) => {
    throw error;
  };

  private generateHeaders() {
    return {
      Authorization: `${this.authClient.getSession()?.token}`,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
  }
}

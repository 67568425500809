// Generated with util/create-component.js
import React from 'react';
import { Button, Divider, Drawer } from 'antd';
import { ArrowLeftOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import env from 'src/env';
import { useAuth } from 'src/hooks/useAuth';

import { INavProps } from './Nav.types';
import styles from './Nav.module.scss';
import HotelSelect from '../HotelSelect/HotelSelect';
import LocaleSelect from '../LocaleSelect/LocaleSelect';

const Nav: React.FC<INavProps> = () => {
  const [showDrawer, setShowDrawer] = React.useState(false);
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const auth = useAuth();

  const shouldRenderBackButton = location.pathname !== '/';
  const leftContent = auth.isAuthenticated ? auth.user?.name : '';

  return (
    <nav data-testid="Nav" className={styles.root}>
      <div>
        {shouldRenderBackButton && (
          <Button
            type="text"
            shape="circle"
            icon={<ArrowLeftOutlined />}
            className={styles.nav_button}
            size="large"
            onClick={() => history.goBack()}
          />
        )}
      </div>
      <a href="/" className={styles.logo}>
        <img src={`${env.apiBaseUrl}/api/public/v1/small_logo`} alt="logo" />
      </a>
      <div className={styles.left_content}>
        <div>{leftContent}</div>
        <Button
          type="text"
          shape="circle"
          icon={<UnorderedListOutlined />}
          className={styles.nav_button}
          size="large"
          onClick={() => setShowDrawer(true)}
        />
      </div>
      <Drawer title={t('general.other.options')} visible={showDrawer} onClose={() => setShowDrawer(false)}>
        <div className={styles.drawer_item}>
          <span>{t('inventory.hotel_td')}</span>
          <HotelSelect onHotelChanged={handleHotelChanged} />
        </div>
        <div className={styles.drawer_item}>
          <span>{t('profile_nav.profile.locale_label')}</span>
          <LocaleSelect />
        </div>
        <Divider />
        <Button onClick={() => auth.logout()}>{t('index.log_out_link')}</Button>
      </Drawer>
    </nav>
  );

  function handleHotelChanged() {
    history.replace('/');
  }
};

export default Nav;
